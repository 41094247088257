import { useRef,useState } from "react"
import ConnectionFinder from "../api/ConnectionFinder";
import ColumnsFc from "./ColumnsFc";
import ColumnsOF from "./ColumnsOf";
import Button from "./Button";
import "../Components/styles/Import.css"
import FileSaver from 'file-saver';
import Esempio from "./Documents/Esempio_Input.xlsx"
import LinearProgress from '@mui/material/LinearProgress';

import { useNavigate } from "react-router-dom";
const xlsx = require("xlsx")

const XLSX = require("xlsx")
// const socket = io(process.env.REACT_APP_API_URL || "http://localhost:3002")

const ImportFile = () =>{
    const ParsedFcColumns =[
        "PROGRESSIVO_RIGA",
        "COMUNE_CLIENTE",
        "PART_TOP_CLIENTE",
        "STRADA_CLIENTE",
        "CIVICO_CLIENTE",
        "COPERTURA_BITSTREAM_NGA_VULA_FTTCab",
        "PIANIFICAZIONE_BITSTREAM_NGA_VULA_FTTCab",
        "VELOCITA_DOWN_BITSTREAM_NGA_VULA_FTTCab",
        "VELOCITA_UP_BITSTREAM_NGA_VULA_FTTCab",
        "DISTANZA_BITSTREAM_NGA_VULA_FTTCab",
        "COPERTURA_BITSTREAM_NGA_VULA_FTTH",
        "PIANIFICAZIONE_BITSTREAM_NGA_VULA_FTTH"
    ]
    const ParsedOfColumns = [
        "cluster",
        "COMUNE",
        "PARTICELLA_TOP",
        "INDIRIZZO",
        "CIVICO",
        "SCALA_PALAZZINA",
        "FTTH",
    ]

    const inpt = useRef()
    const Navigate = useNavigate()
    const [File,setFile] = useState("Seleziona un file...")
    const [Loading,setLoading] = useState(false)
    const sub = async()=>{
        const filereader = new FileReader();
        if (inpt.current.files.length === 0) {
            alert("Selezionare un file")
            return;
        }
        if (inpt.current.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            alert("Il file deve essere in formato .xlsx")
            return
        }
        let filename = document.getElementById("FileName").value + ".xlsx"        
        if (filename === ".xlsx") {
            filename="Export.xlsx"
        }
        filename = filename.trim()
        filereader.readAsArrayBuffer(inpt.current.files[0])
        let workbook;
        await new Promise((resolve)=>{filereader.onload = ()=>{ 
            workbook = XLSX.readFile(filereader.result)    
            resolve()
        }})
        const sheetName = workbook.SheetNames[0]
        const sheet = workbook.Sheets[sheetName]
        let obj = XLSX.utils.sheet_to_json(sheet)
        let headers = {
            "x-access-token": localStorage.getItem('token')
          }
        setLoading(true)
        ConnectionFinder.post("/require/import",obj,{headers})
        .then((response)=>{
            if (!response.data.error && !response.data.err) {
            var workbook = xlsx.utils.book_new();
            const resultsfc = response.data.fc;
            const resultsof = response.data.of;
            const parsedresultsfc = response.data.fc_parsed;
            const parsedresultsof = response.data.of_parsed;
            //aggiungo gli headers ai vari array
            resultsfc.splice(0,0,ColumnsFc.map((value)=>{return value.field}))
            resultsof.splice(0,0,ColumnsOF.map((value)=>{return value.field}))
            parsedresultsfc.splice(0,0,ParsedFcColumns.map((value)=>{return value}))
            parsedresultsof.splice(0,0,ParsedOfColumns.map((value)=>{return value}))
            resultsof[0].splice(0,0,"Cluster")
            //creo le sheets
            var Fc = xlsx.utils.aoa_to_sheet(resultsfc)
            var Of = xlsx.utils.aoa_to_sheet(resultsof)
            var ParsedFc = xlsx.utils.aoa_to_sheet(parsedresultsfc);
            var ParsedOf = xlsx.utils.aoa_to_sheet(parsedresultsof);
            //Aggiungo le sheet al workbook
            xlsx.utils.book_append_sheet(workbook,ParsedFc,"Fibercop_Facilitato")
            xlsx.utils.book_append_sheet(workbook,ParsedOf,"OpenFiber_Facilitato")
            xlsx.utils.book_append_sheet(workbook,Fc,"Fibercop")
            xlsx.utils.book_append_sheet(workbook,Of,"OpenFiber")
            xlsx.utils.book_append_sheet(workbook,sheet,"File_Di_Partenza")
            //modifico la larghezza delle colonne
            Of['!cols'] =  Array(ColumnsOF.length).fill({wch: 25})
            Fc['!cols'] =  Array(ColumnsFc.length).fill({wch: 25})
            ParsedFc['!cols'] =  Array(ParsedFcColumns.length).fill({wch: 25})
            ParsedOf['!cols'] =  Array(ParsedOfColumns.length).fill({wch: 25})
            xlsx.writeFile(workbook,filename)
            }
            else if (response.data.error)
                alert("Errore, Provare un altro file o modificare il corrente")
            else    
                Navigate("/",{replace: true})
            setLoading(false)
            
        })

    }
    const showFileName = (e)=>{
        setFile(e.target.files[0].name)
    }
    return(

        // <div className="editScreenContex">
            <div className="ImportContainer">
                <h1 style={{textAlign: 'left', marginLeft: '20px', color: '#5F6A6A',marginBottom: '0px'}}>Importa il tuo file</h1>
                <div style={{width: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'center'}}>
                    <p style={{fontSize: '1.5rem',  width: '90%',marginBottom: 0}}>Scegli un file da cui ricercare i vari campi, il file dev'essere composto da 4 colonne:</p>
                </div>
                <ul style={{listStyleType: 'none', fontSize: '1.4rem', margin: 0, marginLeft: '10%', color: '#5F6A6A'}}>
                    <li>Comune</li>
                    <li>Particella Toponomastica</li>
                    <li>Indirizzo</li>
                    <li>Civico</li>
                </ul>
                <p style={{fontSize: '1.25rem',  width: '90%', margin: 'auto'}}><span style={{color: '#ff0f64', fontWeight: 'bold'}}>Attenzione:</span> La prima riga del file deve riportare i nomi delle colonne.</p>
                <div style={{justifyContent: 'end',marginBottom: '10%',display: 'flex',flexDirection: 'column',alignItems: 'center',flex: '0 0 0'}} className="InputContainer">
                    <div style={{marginBottom: '20px', width: '80%'}}>
                        <label className="custom-file-upload" style={{width: '30%'}} >
                            <input type="file" onChange={showFileName} ref={inpt} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
                            Scegli il file
                        </label>
                        <span style={{marginLeft: '15px'}}>{File}</span>
                    </div>
                    <div style={{display: 'flex', marginBottom: '20px',justifyContent: 'center', width: '80%'}}>
                        <input type="text" id="FileName" color="red" placeholder="Nome del file Generato" className="custom-file-upload" style={{width: '100%',height: '100%',marginBottom: '15px',cursor: 'auto'}}/>
                        <span className="custom-file-upload" style={{cursor: 'auto',paddingTop: '10px',}}>.xlsx</span>
                    </div>
                    
                    {
                        Loading && 
                        <LinearProgress sx={{
                            backgroundColor: 'white',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: '#ff0f64'
                            }
                          }} style={{width: '80%',backgroundColor: '#ffc3d8'}}/> 
                    }
                    {
                        !Loading &&
                            <div style={{display:'flex',alignItems: 'center',justifyContent: 'center', width: '95%',columnGap: '10px'}}>
                                <Button func={sub} name="Invia" style={{margin: '0',width: '70%'}} />
                                <Button name="Esempio" style={{margin: '0',width: '30%'}}  func={()=>{FileSaver.saveAs(Esempio,"Esempio_Input.xlsx")}}/>
                            </div>
                            
                    }
                </div>
            </div>
        // </div>
    )
}
export default ImportFile