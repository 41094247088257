import React, { useEffect, useState } from 'react';
import './styles/ControlPanel.css';
import ConnectionFinder from '../api/ConnectionFinder';
import { useDispatch } from 'react-redux';
import stateCheck from './Test';
import './styles/Screens.css';
import Alert from './Alert';

import DeleteIcon from './img/delete.png'
// import InfoIcon from './img/info.png'

const AccountManager = () => {

    let username, password, role;

    const [message, setMessage] = useState("...");
    const [accountList, setList] = useState();
    const [alert, setAlert] = useState();
    let alertStatus = false;
    const headers = {
        "x-access-token": localStorage.getItem('token')
    }

    const register = async () => {
        let roleSelect = document.getElementById('role');

        username = document.getElementById('username')['value'];
        password = document.getElementById('password')['value'];
        role = roleSelect.options[roleSelect.selectedIndex].value;

        if(username.trim() === "" || password.trim() === ""){
            setMessage('Campi non completi');
            return;
        }

        await ConnectionFinder.post("/user/register", {
            username: username,
            password: password,
            role: role
        },{headers})

        document.getElementById('username')['value'] = "";
        document.getElementById('password')['value'] = "";

        Refresh()

        setMessage("Account "+ username +" creato.")

    }

    const dispatch = useDispatch();

    const deleteUser =  (id) =>{
        ConnectionFinder.post(`/user/deleteUser`, 
        {id},
        {headers}).then(() => {
            setAlert(<Alert text={`Account eliminato!`} action={() => alertHandler()} actionText="Fatto"/>)
            Refresh()
            setMessage("...")
        })


    }

    const alertHandler = (user, id) => {
        alertStatus = !alertStatus
        alertStatus ? setAlert(<Alert exit={() => alertHandler()} text={`Stai per eliminare l'account '${user}'`} critical={true} action={() => deleteUser(id)}/>) : setAlert()
    }

    const Refresh = () => {  
        ConnectionFinder.get("/user/aList", {headers}).then((response) => {
            if(stateCheck(response, dispatch)){
                setList(
                    response.data.map((item) => (
                        <div className='accountI' key={item.id}>
                            <p>{item.id}</p>
                            <p>{item.username}</p>
                            <p>{item.password}</p>
                            <p>{item.role}</p>
                            <div style={{margin: 'auto', width: '20%', display: 'flex', justifyContent: 'center'}}>
                                <div onClick={() => alertHandler(item.username, item.id)} className="btnShadow" id="deleteBtn" style={{backgroundColor: '#D2042D', width: '40px', height: '40px', borderRadius: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto'}}>
                                    <img src={DeleteIcon} alt="" />
                                </div>
                                {/* <div className="btnShadow" id="deleteBtn" style={{backgroundColor: '#283747', width: '40px', height: '40px', borderRadius: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto', flexDirection: 'row'}}>
                                    <img style={{width: '25px', height: '25px'}} src={InfoIcon} alt="" />
                                </div> */}
                            </div>
                        </div>
                    )) 
                )
            }
        })

        

    }
    useEffect(() => {        
        Refresh();
        // eslint-disable-next-line
    },[])

    return(
        <div className='screen'>
            <div className="cpContainer">
                <h1 style={{textAlign: 'left', marginRight: 'auto', color: '#5F6A6A'}}>Crea account</h1>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <input type="text" placeholder="Username" className="cpInput" id="username"/>  
                    <input type="password" placeholder="Password" className="cpInput" id="password"/>       
                    <select id="role" className='cpInput' style={{'width': '100px'}}>
                        <option>user</option>
                        <option>admin</option>
                    </select>
                </div>
                <p className="cpMessage">{message}</p>
                <button className="rButton btnShadow" onClick={() => register()} style={{backgroundColor: '#ff0f64', color: 'white', borderRadius: '12px'}}>Aggiungi</button>      
            </div>
            <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '20px'}}>
                <h1 style={{color: '#5F6A6A', marginRight: 'auto', marginLeft: '15%'}}>Account esistenti</h1>
                <div className='accountListContainer'> 
                    <div className='listHeader'>
                        <p>ID</p>
                        <p>Username</p>
                        <p>Password</p>
                        <p>Role</p>
                        <p>Azioni</p>
                    </div>
                    <div className='accountListScroll'>
                        {accountList}
                    </div>
                </div>
            </div>
            {alert}
        </div>
    )
}

export default AccountManager;