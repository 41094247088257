import React from "react";
import './styles/navButton.css';
import { useNavigate } from "react-router-dom";

const NavButton = (props) => {
    const text = props.text;
    const icon = props.icon;
    const to = props.to;

    const navigate = useNavigate();

    return(
        <div style={{margin: 'auto', width: '95%', height: '35px'}}>
            <div className="navButton" onClick = {() => navigate(to, {replace: true})}>
                <p style={{fontWeight: 'inherit', color: 'inherit'}}>{text}</p>
                {icon && <img src={icon} alt="lens" style={{marginLeft: '2px'}}/>}
            </div>
        </div>
    )
}

export default NavButton;