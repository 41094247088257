//convert array of array to an array of objects with properties (columns)
const ManageRows = async(rawData,columns,fieldname) =>{
   rawData = await rawData.map((value)=>{
      let fixedValue = {};
      for (let i = 0; i < value.length; i++){
        if (!value[i]) fixedValue[columns[i][fieldname]] = ""
        else
          fixedValue[columns[i][fieldname]] = value[i]
      }
      return fixedValue
  })
  return rawData
}
export default ManageRows;