import React, { useEffect } from 'react';
import GoogleMap from './GoogleMaps';
import convexHull from './ConvexHull';
import ConnectionFinder from '../api/ConnectionFinder';
import AutocompleteCustom from './AutoCompleteCustom';
import Navbar from './Navbar';
import NavButton from './NavButton';
import { useGridApiRef } from '@mui/x-data-grid';
import Cookie from './Cookies';
import Loading from './Loading';
import { DataGrid } from '@mui/x-data-grid';
import ColumnsFibre from './ColumnsFibre';
import "./styles/Fibre.css"
import closepng from "./img/close.png"
var OpenLocationCode = require('open-location-code').OpenLocationCode; 
const FibreConnect = () => { 
  const apiRef = useGridApiRef();
  const [markerCoords, setMarkerCoords] = React.useState([]);
  const [finalcoors, setFinalCoors] = React.useState([]);
  const [Local,setLocal] = React.useState([])
  const [Address,setAddress] = React.useState([])
  const [Civic,setCivic] = React.useState([])
  const [rows, setRows] = React.useState([]);
  const [TextLocation, setTextLocation] = React.useState("");
  const [TextAddress, setTextAddress] = React.useState("");
  const [TextCivic, setTextCivic] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const FetchLocation = async (e) => {
    ConnectionFinder.get("/research/fibreconnect/GetLocations",{headers: {"x-access-token": localStorage.getItem('token')}}).then((response) => {
      const arr = []
      for(const element of response.data){
        arr.push(element.Area_Industriale)
      }
      arr.sort((a,b) => a.localeCompare(b))
      setLocal(arr)
    })
  }
  const FetchCoords = async () => {
    setLoading(true)
    ConnectionFinder.get("/research/fibreconnect/LocationData",
    {
      params:
      {
        local: document.getElementById("Area_Industriale").value, 
        address: document.getElementById("IndirizzoFibre").value,
        civic: document.getElementById("CivicoFibre").value
      },headers: {"x-access-token": localStorage.getItem('token')}}).then((response) => {
        const PlusCodes = [];
        var coordinates = [];
        response.data.forEach((element) => {
          PlusCodes.push([element.plusCODE, element.Indirizzo + " " + element.Numero_Civico]);
        })
        console.log(response.data)
        setRows(response.data)
        PlusCodes.forEach((code) => {
          const olc = new OpenLocationCode();
          const codeArea = olc.decode(code[0]);
          coordinates.push([codeArea.latitudeCenter, codeArea.longitudeCenter, code[1]]);
        })
        setMarkerCoords(coordinates)
        const points = convexHull(coordinates)
        const newCoords = []
        points.forEach((point) => {
          newCoords.push({ lat: point[0], lng: point[1] });
        });
        console.log(newCoords)
        setFinalCoors(newCoords);
        setLoading(false)
      })
  }    

  const GetAdresses = async() => {
    let value = document.getElementById("IndirizzoFibre").value
    
    const data = await ConnectionFinder.get("/research/fibreconnect/GetAddresses",{params: {local: document.getElementById("Area_Industriale").value, address: value}, headers: {'x-access-token': localStorage.getItem('token')}})
    const arr = []
    for(const element of data.data){
      arr.push(element.Indirizzo)
    }
    arr.sort((a,b) => a.localeCompare(b))
    setAddress(arr)
  }

  const GetCivics = async() => {
    setTextCivic("")
    let value = document.getElementById("CivicoFibre").value
    const data = await ConnectionFinder.get("/research/fibreconnect/GetCivics",{params: {local: document.getElementById("Area_Industriale").value, address: document.getElementById("IndirizzoFibre").value, civic: value},headers: {"x-access-token": localStorage.getItem('token')}})
    const arr = []
    for(const element of data.data){
      arr.push(element.Numero_Civico)
    }
    arr.sort((a,b) => a.localeCompare(b))
    setCivic(arr)
  }
  useEffect(() => {
    FetchLocation()
  },[])
  return (
    <div style={{background: 'linear-gradient(347deg, rgba(243,17,98,0.927608543417367) 8%, rgba(41,44,59,1) 61%)', paddingBottom: '30px',minHeight:'calc(100vh - 30px)'}}>
      <Navbar />
      <div className='InputContainerOF'>
        <AutocompleteCustom suggestions={Local} value={TextLocation} setvalue={setTextLocation}  onSelect={()=>GetAdresses()}label="Area Industriale" id="Area_Industriale" className="AutoCompleteFibre"/>
        <AutocompleteCustom suggestions={Address} value={TextAddress} setvalue={setTextAddress} onSelect={()=>GetCivics()}   label="Indirizzo" id="IndirizzoFibre" className="AutoCompleteFibre"/>
        <AutocompleteCustom suggestions={Civic} value={TextCivic} setvalue={setTextCivic}  label="Civico" id="CivicoFibre" className="AutoCompleteFibre"/>
        <div className='btn' style={{width: '100vw'}} onClick={()=>FetchCoords()}>Cerca</div>
      </div>
      <NavButton text="Indietro" to="/home"/>
      <p style={{ color: 'white', fontWeight: 'bold', fontSize: '40px',margin: '0', textAlign: 'center'}}>Risultati</p>
      {loading ? <Loading /> : <div className='grid' >
        <DataGrid  
          rows={rows}
          column
          columns={ColumnsFibre} 
          apiRef={apiRef} 
          getRowId={(row) => row.Id}
          />
        
      </div>}
      {
        rows.length === 0 ? <></> : 
        <div className='btn' style={{width: '15vw',marginTop: '2vh'}} onClick={()=>document.getElementById("MapModal").showModal()}>Mostra Mappa</div>
      }
      <dialog className='dialogmap' id='MapModal'>
        <img src={closepng} alt='CloseDialog' id='CloseDialog' onClick={()=>document.getElementById("MapModal").close()} style={{cursor: 'pointer'}}/>
        {markerCoords.length === 0 && finalcoors.length === 0 ? <p style={{color: 'white', fontSize: '20px', textAlign: 'center'}}>Nessun risultato trovato</p> :
          <GoogleMap markerCoords={markerCoords} coordinates={finalcoors} address={rows}/>
        }
      </dialog>
      <Cookie />
    </div>
  );
}
export default FibreConnect;
