import React from 'react';
import "./styles/style.css";
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux';
import ConnectionFinder from '../api/ConnectionFinder';

let Navbar = () => {

    const navigate = useNavigate()
    const role = useSelector(state => state.roleManager)

    const logOut = () => {
        ConnectionFinder.get('/user/logOut', {
                headers: {'x-access-token': localStorage.getItem('token')}
            }
        )
        localStorage.setItem('token', null)
        navigate('/', {replace: true})

    }

    return(
        <div className="navbar">
            <p style={{float: 'left', color: 'white', fontWeight: 'bold', fontSize: '30px', marginLeft: '5px'}}>Internal</p>
            <div style={{marginRight: '30px'}}>
                <p style={{float: 'right', color: 'white', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer' }} onClick={() => logOut()}>Esci</p>
                {role === "admin" && <p style={{float: 'right', color: 'white', marginRight: '30px', fontSize: '20px', cursor: 'pointer'}} onClick={() => navigate('/cp', {replace: true})}>Admin</p>}                    
            </div>
        </div>
    )

}

export default Navbar;