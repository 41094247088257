import React,{useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './styles/style.css'
import { makeStyles } from "@material-ui/core/styles";
import ConnectionFinder from '../api/ConnectionFinder';
import Simlaritysort from './SimilaritySort';
import { useDispatch,useSelector } from 'react-redux';
import { setEgon } from '../actions';
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: "translate(34px, 20px) scale(0.9);",
    },
    "&.Mui-focused .MuiInputLabel-outlined": {
      color: "#ff0f64"
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "18px"

    },  
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
      borderRadius: "15px",
      borderWidth: "3px",
      fontSize: "17px",
    },
    "@media screen and (max-width: 700px )":
    {
        "& .MuiInputBase-root": {
            width: '100%',
            marginLeft: "0px",
        }
    },
    
  },
  inputRoot: {
    color: "black",
    backgroundColor: "white",
    borderRadius: "15px",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      borderRadius: "15px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ff0f64",
      borderRadius: "15px",
      borderWidth: "3px"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
      borderRadius: "15px",
      borderWidth: "3px"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "ff0f64",
      borderRadius: "15px",
      borderWidth: "3px",
      color: "red"
    },
    "&.Mui-checked .MuiOutlinedInput-notchedOutline": {
      borderColor: "ff0f64",
      borderRadius: "15px",
      borderWidth: "3px",
      color: "red"
    }
  },

  
}));

const FreeSolo = ({label}) => {
  const [cities, setCities] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [EgonList, setEgonList] = useState([])
  let headers = {
    "x-access-token": localStorage.getItem('token')
  }
  let ricercaComune = (e) => {
    const value = e.target.value;
    if(value.length === 0) {
      setCities([])
      setAddresses([])
      setNumbers([])
    }
    
    if(value.length !== 3) return;
    ConnectionFinder.post("/research/openfiber/city", {
      name: value,
    },{headers}).then((response) => {
      if (response.data.status === false) return
      response.data = Simlaritysort(response.data,value,"Denominazione_in_italiano");
      setCities(response.data)
    })

  }
  let ricercaIndirizzo = (e) => {
    const value = e.target.value;
    const CityName = document.getElementById('cname').value;

    if(CityName.trim().length === 0) return alert("Inserisci prima il comune.");

    if(value.length === 0) {
      setCities([])
      setAddresses([])
      setNumbers([])
    }
    else if(value.length === 3) {      
      ConnectionFinder.post("/research/openfiber/address", {
        name: value,
        city: CityName,
      },{headers}).then((response) => {
        if (response.data.status === false) return
        setEgonList(response.data)
        response.data = Simlaritysort(response.data,value,"address")
        setAddresses(response.data)
        setCities([])
      
      })
    }
  }
  let EgonRedux = useSelector(state => state.Egon);
  const dispatch = useDispatch()
  let ricercaCivico = (e) => {
    const value = e.target.value;
    const address = document.getElementById('address').value;
    if(address.trim().length === 0) return alert("Inserisci prima l'indirizzo.");
    if(value.length === 0) {
      setCities([])
      setAddresses([])
      setNumbers([])
    }
    const city = document.getElementById('cname').value
    ConnectionFinder.post("/research/openfiber/number", {
      name: value,
      address: EgonRedux,
      city
    },{headers}).then((response) => {
      if (response.data.status === false) return 
      setNumbers(response.data)
      setAddresses([])
      setCities([])
    })

  }
  const classes = useStyles();
  const EgonSelected = async (e,value) =>{
    const index = EgonList.map((e) =>{return e.address}).indexOf(value)
    if (index !== -1){
      const egon = EgonList[index].egon.trim();
      dispatch(setEgon(egon))
    }
    else  
      dispatch(setEgon(""))
  }
  var sus;
  if(label === "Comune") {
    sus = (<div  className='textContainer' id='cityContainerOF'>
    <Autocomplete
      classes={classes}
      id="cname"
      noOptionsText = "Nessun risultato"
      openText='Apri'
      closeText=''
      disableClearable
      options={cities.map((option) => option.Denominazione_in_italiano.toUpperCase())}
      renderInput={(params) => (
      <TextField {...params} label={label} onChange={(e) => ricercaComune(e)}  variant="outlined" required/>
      )}
    />
  </div>);
  }

  if(label === "Indirizzo") {
    sus = (<div  className='textContainer'>
    <Autocomplete
      disablePortal
      id="address"
      onChange={(e,value)=>{EgonSelected(e,value)}}
      noOptionsText = "Nessun risultato"
      openText='Apri'
      closeText=''
      disableClearable
      options={addresses.map((option) => option.address)}
      renderInput={(params) => (
      <TextField {...params} label={label} onChange={(e) => ricercaIndirizzo(e)} variant="outlined" required/>
      )}
      classes={classes}
    />
  </div>);
  }
  if(label === "Civico") {
    sus = (<div  className='textContainer'>
    <Autocomplete
      classes={classes}
      id="number"
      noOptionsText = "Nessun risultato"
      openText='Apri'
      closeText=''
      disableClearable
      options={numbers.map((option) => option.civic)}
      renderInput={(params) => (
      <TextField {...params} label={label} onChange={(e) => ricercaCivico(e)}  variant="outlined" required/>
      )}
    />
  </div>);
  }
  return sus;
}
export default FreeSolo;