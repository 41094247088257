import React, {useEffect, useState} from "react";
import './styles/Login.css';
import Logo from './img/1.svg';
import { useNavigate } from 'react-router-dom';
import ConnectionFinder from "../api/ConnectionFinder";
import { useDispatch } from "react-redux";
import { logIn, logOut, setRole } from "../actions";
import Cookie from "./Cookies";

const Login = () => {

    let username, password;
    const navigate = useNavigate();

    const [message, setMessage] = useState("Inserisci username e password");

    ConnectionFinder.defaults.withCredentials = true;

    const dispatch = useDispatch();

    const  login = () => {

        username = document.getElementById("username").value;
        password = document.getElementById("password").value;

        
        ConnectionFinder.post("/user/login", {
            username: username,
            password: password
        }).then((response) => {
            if(response.data.auth){
               localStorage.setItem('token', response.data.token)
               dispatch(logIn())
               dispatch(setRole(response.data.role))
               navigate('/home', {replace: true})
            }else{
                setMessage(response.data.message)
            }
        })
    }

    useEffect(() => {
        ConnectionFinder.get("/user/login1", {
            headers: {
                "x-access-token": localStorage.getItem('token')
            }
        }).then((response) => {
            if(response.data.auth) {
                dispatch(logIn())
                navigate('/home', {replace: true})
            }
            else {
                dispatch(logOut())
            }
        })
    },[navigate, dispatch])


    return(
        <div style={{"backgroundColor" : "#ff0f64", "width": "100%", "height": "100vh","margin":'0', "display":"flex", "justifyContent": "center", "flexDirection": "column", "alignItems": "center"}}>
            <div className="lContainer">
                <img src={Logo} className="Logo" alt="logo"/>
                <h1 className="lTitle">Login</h1>
                <input type="text" placeholder="Username" className="lInput" id="username"/>
                <input type="password" placeholder="Password" className="lInput" id="password"/>
                <button className="rButton" onClick={() => login()}>Accedi</button>
                <p style={{margin: 0, color: "white", justifySelf: 'end', marginBottom: '1px'}}>{message}</p>
            </div>
            <Cookie />
        </div>
    )
}

export default Login;